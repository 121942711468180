.card {
    @apply bg-white shadow-md p-8 w-full mx-auto rounded-lg;
}

label {
    @apply block font-semibold mt-3;
}

button[type="submit"] {
    @apply mt-6 bg-[#ebba05] p-2 block text-white shadow-md w-full rounded-lg font-bold hover:bg-[#d9ac04];
}

h1 {
    @apply font-bold text-2xl;
}

h2 {
    @apply text-gray-500
}

.glasses_button {
    @apply shrink-0 bg-gray-100 w-10 h-10 rounded-lg border-gray-200 border hover:bg-gray-200;
}

input, select {
    @apply mt-1 p-2 border-gray-200 border bg-gray-100 rounded-lg w-20;
}