.beer {
    background: url('/public/images/beer.svg') 0 100% repeat-x, url('/public/images/beer.svg') 300px 100% repeat-x;
    min-height: 100px;
}

.app {
    @apply bg-[#f4cc36] min-h-screen;
    background-image: url('/public/images/pattern.svg');
    background-repeat: repeat;
    font-family: 'Ubuntu';
}